// See more @ https://docs.sentry.io/platforms/javascript

declare const window: any
declare var shared: any

class SentryVendor {
    static init: () => Promise<any> = async () => {
        if (!shared.sentry.dsn) {
            return await Promise.resolve()
        }

        // Config
        const Sentry = await import('@sentry/browser')
        window.Sentry = Sentry
        const { default: resolveConfig } = await import(
            'tailwindcss/resolveConfig'
        )
        const { default: tailwindConfig } = await import(
            // @ts-ignore
            '../../../tailwind.config.js'
        )
        const tailwind = resolveConfig(tailwindConfig)
        const tracesSampleRate =
            shared.app.traces_sample_rate &&
            parseFloat(shared.app.traces_sample_rate)
        const replaysSessionSampleRate =
            shared.sentry.replays_session_sample_rate &&
            parseFloat(shared.sentry.replays_session_sample_rate)
        const replaysOnErrorSampleRate =
            shared.sentry.replays_on_error_sample_rate &&
            parseFloat(shared.sentry.replays_on_error_sample_rate)

        // Sentry init
        Sentry.init({
            dsn: shared.sentry.dsn,
            environment: shared.app.env,
            tracesSampleRate,
            replaysOnErrorSampleRate,
            replaysSessionSampleRate,
        })

        // Set user context
        Sentry.setUser({
            id: shared.auth.id,
            name: shared.auth.name,
            email: shared.auth.email,
        })

        // Bug Feedbacks Integration
        const feedback = new Sentry.Feedback({
            autoInject: false,
            colorScheme: 'light',
            showBranding: false,
            // User
            useSentryUser: { email: 'email', name: 'name' },
            isNameRequired: true,
            isEmailRequired: true,
            // Translations
            buttonLabel: shared.trans['Report a Bug'],
            submitButtonLabel: shared.trans['Send Bug Report'],
            cancelButtonLabel: shared.trans['Cancel'],
            formTitle: shared.trans['Report a Bug'],
            nameLabel: shared.trans['Name'],
            namePlaceholder: shared.trans['Your Name'],
            emailLabel: shared.trans['Email'],
            emailPlaceholder: shared.trans['your.email@example.org'],
            messageLabel: shared.trans['Description'],
            messagePlaceholder:
                shared.trans["What's the bug? What did you expect?"],
            successMessageText: shared.trans['Thank you for your report!'],
            // Colors
            themeLight: {
                success: tailwind.theme.colors['wt-green-d'],
                error: tailwind.theme.colors['wt-red-d'],
                submitBackground: tailwind.theme.colors['wt-blue-dr'],
                submitBackgroundHover: tailwind.theme.colors['wt-blue-d'],
                submitBorder:
                    tailwind.theme.colors['wt-blue-dr'] + ' solid 1px',
                submitOutlineFocus: tailwind.theme.colors['wt-blue-lr'],
                cancelBackground: '#fff',
                cancelBackgroundHover: tailwind.theme.colors['wt-gray-lr'],
                cancelBorder:
                    tailwind.theme.colors['wt-gray-dr'] + ' solid 1px',
                cancelOutlineFocus: tailwind.theme.colors['wt-gray-lr'],
                cancelForeground: tailwind.theme.colors['wt-gray-dr'],
            },
        })
        Sentry.addIntegration(feedback)
        // @ts-ignore
        feedback.attachTo(document.getElementById('bug-feedback'))

        // Browser Tracing Integration
        if (tracesSampleRate) {
            Sentry.addIntegration(new Sentry.BrowserTracing())
        }

        // Replays Integration
        if (replaysSessionSampleRate || replaysOnErrorSampleRate) {
            Sentry.addIntegration(
                new Sentry.Replay({
                    blockAllMedia: true,
                })
            )
        }

        return await Promise.resolve()
    }
}

export default SentryVendor
