// See more @ https://intl-tel-input.com

let loaded: boolean = false

class IntlTelInputVendor {
    static init: () => void = () => {
        document.addEventListener('input:tel:validator:load', async () => {
            const inputTelValidatorLoadedEvent = new Event(
                'input:tel:validator:loaded'
            )

            if (loaded) {
                window.dispatchEvent(inputTelValidatorLoadedEvent)
                return
            }
            loaded = true

            const { default: intlTelInput } = await import('intl-tel-input')
            //@ts-ignore
            await import('intl-tel-input/build/css/intlTelInput.css')

            window.intlTelInput = intlTelInput

            window.dispatchEvent(inputTelValidatorLoadedEvent)
        })
    }
}

export default IntlTelInputVendor
